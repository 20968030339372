import React, { useEffect } from 'react'
// components
import LoginForm from './LoginForm'
import HeaderTitle from '../../components/others/HeaderTitle'
// others
import { GAPageView, initGA } from '../../index'

// component
function Login() {
    // Google analytics
    useEffect(() => {
        initGA()
        GAPageView()
    }, [])

    // template
    return (
        <div className="cms">
            <div className="content-container" style={{ width: '100%' }}>
                <HeaderTitle title="Prihlásenie do systému" style={{ textAlign: 'center' }} />
                <section>
                    <LoginForm />
                </section>
            </div>
        </div>
    )
}

export default Login
