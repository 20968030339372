import React, { useState } from 'react'
// store
import {
    isEmailError,
    isICOError,
    isICDPHError,
    isDICError,
    isPhoneError,
    isEmpty,
} from '../../../../store/services/helpers/validation'
import { userService } from '../../../../store/services/ServiceUsers'
// components
import { Form, Button, Message, Icon, Divider } from 'semantic-ui-react'

const options = [
    { key: '1', text: 'Živnostník', value: 1 },
    { key: '2', text: 'Právnická osoba', value: 2 },
]

const UsersForm = (props) => {
    const [form, setForm] = useState({
        firstname: '',
        lastname: '',
        nickname: '',
        email: '',
        phone: '',
        id_number: '',
        tax_mode: 1,
        vat_number: '',
        dit_number: '',
        provider_type: 2,
        is_provider: true,
        is_coach: false,
        is_town: false,
        billing_street: '',
        billing_street_nr: '',
        billing_city: '',
        billing_zip: '',
        business_folder: '',
    })

    const [isAddressShow, setIsAddressShow] = useState(false)
    const [errors, setErrors] = useState(null)

    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleChange = (e, { name, value }) => {
        setErrorMessage('')
        setErrors(null)
        setForm((prevState) => ({ ...prevState, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors(null)
        setIsProcessing(true)
        const request = await userService.registerProvider(form)

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            props.setData((prev) => [request.response, ...prev])
            let total = parseInt(props.totalProviders) + 1
            props.setTotalProviders('(' + total + ')')
            props.setOpen(false)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorMessage && (
                <Message warning>
                    <Message.Header>
                        <Icon name="warning circle" />
                        {errorMessage}
                    </Message.Header>
                </Message>
            )}

            <div style={{ fontWeight: 'bold', paddingBottom: '0.5rem', fontSize: '0.95rem' }}>
                Vyberte režim zadavateľa: <sup style={{ color: 'red' }}>*</sup>
            </div>
            <Form.Group>
                <Form.Radio
                    label="#APbusiness"
                    checked={!form.is_town}
                    style={{ fontWeight: !form.is_town ? 'bold' : 'normal' }}
                    onChange={() => setForm((prev) => ({ ...prev, is_town: false }))}
                />
                <Form.Radio
                    label="#APsolidarity"
                    checked={form.is_town}
                    style={{ fontWeight: form.is_town ? 'bold' : 'normal' }}
                    onChange={() => setForm((prev) => ({ ...prev, is_town: true }))}
                />
            </Form.Group>

            {form.is_town ? (
                <p style={{ opacity: '0.8', fontSize: '0.9rem' }}>
                    <Icon name="checkmark" color="green" /> Možnosť publikovať <strong>modré (#APsolidarity)</strong> a{' '}
                    <strong>zelené (#APevent)</strong> body na mape.
                </p>
            ) : (
                <p style={{ opacity: '0.8', fontSize: '0.9rem' }}>
                    <Icon name="checkmark" color="green" /> Možnosť publikovať <strong>žlté (#APimage)</strong> a{' '}
                    <strong>červené (#APwalkIn)</strong> body na mape.
                </p>
            )}

            <Divider />

            <Form.Group widths="equal">
                <Form.Input
                    required
                    label="Názov spoločnosti"
                    name="name"
                    width="10"
                    value={form.name}
                    onChange={handleChange}
                    placeholder="Názov spoločnosti"
                />
                <Form.Select
                    required
                    label="Typ spoločnosti"
                    options={options}
                    width="4"
                    value={form.provider_type}
                    name="provider_type"
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Input
                    required
                    label="IČO"
                    onChange={handleChange}
                    name="id_number"
                    value={form.id_number}
                    placeholder="vložte ičo"
                    error={
                        form.id_number !== '' ? isICOError(form.id_number) || errors?.id_number?.[0] || false : false
                    }
                />
                <Form.Input
                    label="DIČ"
                    onChange={handleChange}
                    name="dit_number"
                    value={form.dit_number}
                    placeholder="vložte dič"
                    error={form.dit_number !== '' ? isDICError(form.dit_number) || false : false}
                />
                <Form.Input
                    label="IČ DPH"
                    onChange={handleChange}
                    name="vat_number"
                    value={form.vat_number}
                    placeholder="vložte ic dph"
                    error={form.vat_number !== '' ? isICDPHError(form.vat_number) || false : false}
                />
            </Form.Group>

            <Form.Input
                label="Zapísaný v registri"
                placeholder="..."
                value={form.business_folder}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, business_folder: value }))}
            />

            <Form.Group widths="equal">
                <Form.Select
                    label="Typ platiteľa"
                    options={[
                        { key: 1, value: 1, text: 'Platiteľ DPH' },
                        { key: 2, value: 2, text: 'Neplatiteľ DPH' },
                        { key: 3, value: 3, text: 'Registrovaný podľa §7, 7a' },
                    ]}
                    value={form.tax_mode}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, tax_mode: value }))}
                    error={errors?.tax_mode?.[0] || false}
                />
                <Form.Input
                    label="Telefónne číslo"
                    placeholder="+421901234567"
                    value={form.phone}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, phone: value }))}
                    error={!isEmpty(form.phone) ? isPhoneError(form.phone) || false : false}
                />
            </Form.Group>
            {form.provider_type === 1 && (
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        label="Meno"
                        name="firstname"
                        value={form.firstname}
                        onChange={handleChange}
                        placeholder="Meno"
                    />

                    <Form.Input
                        fluid
                        name="lastname"
                        value={form.lastname}
                        onChange={handleChange}
                        label="Priezvisko"
                        placeholder="Priezvisko"
                    />
                </Form.Group>
            )}

            <Divider />

            <Form.Checkbox
                label="Nastaviť fakturačnú adresu"
                checked={isAddressShow}
                onChange={() => setIsAddressShow(!isAddressShow)}
            />

            {isAddressShow && (
                <>
                    <Form.Group>
                        <Form.Input
                            width={10}
                            fluid
                            label="Ulica"
                            placeholder="Ulica"
                            value={form.billing_street}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, billing_street: value }))}
                        />
                        <Form.Input
                            width={6}
                            fluid
                            label="Popisné číslo"
                            placeholder="Popisné číslo"
                            value={form.billing_street_nr}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, billing_street_nr: value }))}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            width={10}
                            fluid
                            label="Mesto/Obec"
                            placeholder="Mesto/Obec"
                            value={form.billing_city}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, billing_city: value }))}
                        />
                        <Form.Input
                            width={6}
                            fluid
                            label="PSČ"
                            placeholder="PSČ"
                            value={form.billing_zip}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, billing_zip: value }))}
                        />
                    </Form.Group>
                </>
            )}

            <Divider />

            <h3>Nastavenie účtu</h3>

            <Form.Group widths="equal">
                <Form.Input
                    required
                    label="Zadajte platný email"
                    onChange={handleChange}
                    name="email"
                    value={form.email}
                    placeholder="example@example.com"
                    error={form.email !== '' ? isEmailError(form.email) || errors?.email?.[0] || false : false}
                />

                <Form.Input
                    name="nickname"
                    value={form.nickname}
                    onChange={handleChange}
                    label="Prezývka"
                    placeholder="Prezývka"
                />
            </Form.Group>

            <p style={{ opacity: '0.8', fontSize: '0.9rem' }}>
                <Icon name="info circle" /> Na zadaný email{' '}
                {form.email ? (
                    <strong style={{ color: 'black' }}>{form.email}</strong>
                ) : (
                    <strong style={{ color: 'red' }}>neuvedený email</strong>
                )}{' '}
                sa odošle email pre aktiváciu účtu.
            </p>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <Button type="button" onClick={() => props.setOpen(false)}>
                    Zrušiť
                </Button>
                <Button
                    content="Potvrdiť"
                    labelPosition="right"
                    loading={isProcessing}
                    disabled={isProcessing}
                    icon="checkmark"
                    color="pink"
                />
            </Form.Field>
        </Form>
    )
}

export default UsersForm
