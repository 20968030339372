// react
import React /*useState, useEffect*/ from 'react'
// import { useDispatch, useSelector } from "react-redux";
// components
// import Galery from "react-photo-gallery";
import Slider from 'react-slick'
import { /*Button,*/ Container, Image } from 'semantic-ui-react'
// others
import '../../styles/gallery.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import { addToBasket, removeFromBasket } from "../../store/actions";

// component
function Gallery(props) {
    const { photos } = props
    // eslint-disable-next-line
    // const [show, setShow] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
    }

    // const dispatch = useDispatch();
    // const basket = useSelector((state) => state.basket);

    // function ShowMore() {
    //     setShow((showPrev) => !showPrev);
    // }

    /*useEffect(() => {
        for (let i = 0; i < photos.length; i++){
            photos[i].src = photos[i].src.replace("http", "https")
        }

    }, [photos])*/

    // template
    return (
        <Container className="ap-slider" style={{ width: '75%', paddingLeft: '1rem', paddingRight: '1rem' }}>
            <div>
                <Slider {...settings}>
                    {photos.map((item) => (
                        <Image src={item.src} style={{ width: '100%' }} />
                    ))}
                </Slider>
            </div>
        </Container>
    )
}

export default Gallery
