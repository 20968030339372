// react
import React, { Fragment } from "react";
// components
import {
    Card,
    Header,
    Grid,
    Modal,
    Button,
    Icon,
    Label,
} from "semantic-ui-react";

// component
function PlanningAddEventType({ eventType, changeEventType, nextModalStep }) {
    //template
    return (
        <Fragment>
            <Modal.Content>
                <Header style={{ textAlign: "center" }}>
                    Vyber typ pre naplanovanie aktivity
                </Header>
                <Grid columns={2} divided centered>
                    <Grid.Row>
                        <Grid.Column>
                            <Card
                                centered
                                width={8}
                                onClick={
                                    eventType !== "exactTime"
                                        ? () => changeEventType("exactTime")
                                        : null
                                }
                            >
                                <Label
                                    attached="bottom"
                                    color={
                                        eventType === "exactTime"
                                            ? "pink"
                                            : undefined
                                    }
                                    style={{ textAlign: "center" }}
                                >
                                    {eventType === "exactTime"
                                        ? "Vybrané"
                                        : "Nevybrané"}
                                </Label>
                                <Card.Content>
                                    <Card.Header>Konkrétny čas</Card.Header>
                                    <Card.Description>
                                        <Header as="h5">
                                            Vyberte čas kedy sa aktivita má
                                            začať
                                        </Header>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <Card
                                centered
                                width={8}
                                onClick={
                                    eventType !== "interval"
                                        ? () => changeEventType("interval")
                                        : null
                                }
                            >
                                <Label
                                    attached="bottom"
                                    color={
                                        eventType === "interval"
                                            ? "pink"
                                            : undefined
                                    }
                                    style={{ textAlign: "center" }}
                                >
                                    {eventType === "interval"
                                        ? "Vybrané"
                                        : "Nevybrané"}
                                </Label>
                                <Card.Content>
                                    <Card.Header>Časový interval</Card.Header>
                                    <Card.Description>
                                        <Header as="h5">
                                            Naplanujte viac termínov v intervale
                                        </Header>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="pink"
                    labelPosition="right"
                    onClick={nextModalStep}
                    icon
                >
                    Pokračovať
                    <Icon name="right chevron" />
                </Button>
            </Modal.Actions>
        </Fragment>
    );
}

export default PlanningAddEventType;
