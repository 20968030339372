import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom';
// routes
// import { routes } from '../../../../store/routes/index';
// components
import DataTable from '../../../../components/tables/DataTable'

// store
import { userService } from '../../../../store/services/ServiceUsers'
// components
import DimmerLoader from '../../../../components/others/DimmerLoader'
import { Button, Icon, Popup } from 'semantic-ui-react'
import ModalConfirm from '../../../../components/modals/ModalConfirm'
import moment from 'moment'

const UsersTable = ({ data, setData, isLoading }) => {
    // PORADIE HLAVICIEK
    const orderedHeaders = ['id', 'name', 'email', 'date_joined', 'last_login', 'is_active', 'type', 'tableActions']

    // CUSTOM COMPONENT NA AKCIE
    const TableAction = ({ row, index }) => {
        return (
            //     <Button icon="eye" as={Link} to={routes.EMPLYOEES_DETAIL+row.id} style={{ background: "none", color: "var(--info)" }}/>
            <Button.Group>
                {row.is_coach ? (
                    <Popup
                        content="Zrušiť označenie kouč"
                        trigger={
                            <Button icon color="orange" onClick={() => setAsCouch(row, index)}>
                                <Icon name="male" />
                            </Button>
                        }
                    />
                ) : (
                    <Popup
                        content="Nastaviť označenie kouč"
                        trigger={
                            <Button icon onClick={() => setAsCouch(row, index)}>
                                <Icon name="male" />
                            </Button>
                        }
                    />
                )}
                {row.is_town ? (
                    <Popup
                        content="Zrušiť režim #APsolidarity (mesto)"
                        trigger={
                            <Button icon color="blue" onClick={() => setAsTown(row, index)}>
                                <Icon name="chess rock" />
                            </Button>
                        }
                    />
                ) : (
                    <Popup
                        content="Nastaviť režim #APsolidarity (mesto)"
                        trigger={
                            <Button icon onClick={() => setAsTown(row, index)}>
                                <Icon name="chess rock" />
                            </Button>
                        }
                    />
                )}
                {row.is_active ? (
                    <Popup
                        content="Odobrat prístup zadavateľovy do systému"
                        trigger={
                            <Button icon color="red" onClick={() => toggleProviderActive(row, index)}>
                                <Icon name="times" />
                            </Button>
                        }
                    />
                ) : (
                    <Popup
                        content="Aktivovať prístup do systému"
                        trigger={
                            <Button icon color="pink" onClick={() => toggleProviderActive(row, index)}>
                                <Icon name="checkmark" />
                            </Button>
                        }
                    />
                )}
            </Button.Group>
        )
    }

    //HLAVICKY A ICH POMENOVANIE + VLASTNOSTI
    const headers = {
        _id: {
            text: 'Id',
            invisible: true,
            filterable: false,
            transform: (value) => `#${value}`,
        },
        name: {
            text: 'Meno',
            sortable: true,
            filterable: true,
        },
        email: {
            text: 'Email',
            sortable: true,
            filterable: true,
        },
        date_joined: {
            text: 'Dátum registrácie',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return getDate(row.date_joined)
            },
        },
        last_login: {
            text: 'Posledná aktivita',
            sortable: true,
            invisible: true,
            filterable: true,
            transform: (value, index, row) => {
                return row.last_login
            },
        },
        is_active: {
            text: 'Stav',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return stateOfProvider(row.is_active)
            },
        },
        tableActions: {
            text: 'Akcie',
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} index={index} />
            },
        },
        role: {
            invisible: true,
        },
        is_town: {
            invisible: true,
        },
        is_coach: {
            invisible: true,
        },
        is_provider: {
            invisible: true,
        },
        type: {
            text: 'Typ',
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return getType(row)
            },
        },
    }

    // state
    const [openModal, setOpenModal] = useState(false)
    const [id, setId] = useState()
    // eslint-disable-next-line
    const [index, setIndex] = useState()
    const [provider, setProvider] = useState({})
    const [action, setAction] = useState('')
    const [content, setContent] = useState('Vykonať zmenu')
    const [confirmButton, setConfirmButton] = useState('Potvrdiť')
    let header = 'Potvrdenie zmeny'
    let cancelButton = 'Zrušiť'
    const [state, setState] = useState([])

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setState(data)
        }

        fetchData()
    }, [data])

    async function toggleProviderActive(input, idx) {
        setIndex(idx)
        setId(input.id)
        setProvider(input)
        setAction('active')
        if (input.is_active) {
            setContent('Naozaj chcete deaktivovať poskytovateľa?')
            setConfirmButton('Deaktivuj')
        } else {
            setContent('Naozaj chcete aktivovať poskytovateľa?')
            setConfirmButton('Aktivuj')
        }
        setOpenModal(true)
    }
    async function setAsTown(input, idx) {
        setId(input.id)
        setAction('town')
        setProvider(input)
        setIndex(idx)
        if (input.is_town) {
            setContent('Naozaj chcete deaktivovať typ poskytovateľa ako mesto?')
            setConfirmButton('Deaktivuj')
        } else {
            setContent('Naozaj chcete aktivovať typ poskytovateľa ako mesto?')
            setConfirmButton('Aktivuj')
        }
        setOpenModal(true)
    }
    async function setAsCouch(input, idx) {
        setIndex(idx)
        setId(input.id)
        setAction('coach')
        setProvider(input)
        if (input.is_coach) {
            setContent('Naozaj chcete deaktivovať typ poskytovateľa ako kouč?')
            setConfirmButton('Deaktivuj')
        } else {
            setContent('Naozaj chcete aktivovať typ poskytovateľa ako kouč?')
            setConfirmButton('Aktivuj')
        }
        setOpenModal(true)
    }
    async function confirmModal() {
        if (action === 'active') {
            toggleActive()
            await userService.setProviderActivity(id)
        } else if (action === 'town') {
            if (provider.is_coach) {
                await userService.setProviderAsCouch(id)
            }
            await userService.setProviderAsTown(id)
            toggleTown()
        } else if (action === 'coach') {
            if (provider.is_town) {
                await userService.setProviderAsTown(id)
            }
            await userService.setProviderAsCouch(id)
            toggleCouch()
        }
        setOpenModal(false)
    }
    function cancelModal() {
        setOpenModal(false)
    }
    function getDate(input) {
        return moment(input).format('DD.MM.YYYY')
    }
    function stateOfProvider(input) {
        if (input) {
            return 'Aktívny'
        } else {
            return 'Neaktívny'
        }
    }

    function getType(input) {
        if (input.is_coach) {
            return 'Kouč'
        }
        if (input.is_town) {
            return 'Mesto'
        }
        return 'Zadávateľ'
    }
    function toggleCouch() {
        if (provider.is_couch) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.is_coach = false
                    }

                    return item
                })
            )
        }
        if (provider.is_town) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.is_coach = true
                        item.is_town = false
                    }

                    return item
                })
            )
        }
        if (!provider.is_coach) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.is_coach = true
                    }

                    return item
                })
            )
        }
    }
    function toggleTown() {
        if (provider.is_town) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.is_town = false
                    }

                    return item
                })
            )
        }
        if (provider.is_coach) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.is_town = true
                        item.is_coach = false
                    }

                    return item
                })
            )
        }
        if (!provider.is_town) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.is_town = true
                    }

                    return item
                })
            )
        }
    }
    function toggleActive() {
        setData((prev) =>
            prev.filter((item) => {
                if (item.id === id) {
                    item.is_active = !provider.is_active
                }

                return item
            })
        )
    }
    return (
        <>
            <DimmerLoader active={isLoading} />
            <DataTable data={state} orderedHeaders={orderedHeaders} headers={headers} />
            <ModalConfirm
                open={openModal}
                header={header}
                content={content}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                onCancel={() => cancelModal()}
                onConfirm={() => confirmModal()}
            />
        </>
    )
}
export default UsersTable
