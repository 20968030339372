import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
// store
import { searchService } from '../../store/services/ServiceSearch'
// components
import Navbar from '../../components/general/Navbar'
import Footer from '../../components/general/Footer'
import Gallery from '../../components/others/Gallery'
import ActivityInfoSection from './ActivityInfoSection'
// import Map from "../../components/others/Map";
import { Container, Header, Loader, Label } from 'semantic-ui-react'
// component
export default function Activity(props) {
    const history = useHistory()
    const [position, setPosition] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(false)
    const [activityId] = useState(props.match.params.id)
    const [activityGallery, setActivityGallery] = useState([])

    useEffect(() => {
        async function fetchData(id) {
            setIsLoading(true)
            const result = await searchService.getEventByID(id)

            if (result.status === 200) {
                setData(result.response)
            } else if (result.status === 404) {
                history.push('/404')
                return
            }

            createGallery(result.response)
            setIsLoading(false)
        }

        fetchData(activityId)
    }, [activityId, history])

    const getQueryParamsToNavigation = () => {
        return (
            'https://www.waze.com/sk/livemap/directions?navigate=yes&from=' +
            position?.lat +
            ',' +
            position?.lng +
            '&to=' +
            data?.address?.latitude +
            ',' +
            data?.address?.longitude +
            ''
        )
    }

    const initCurrentPosition = useCallback(() => {
        if ('geolocation' in navigator) {
            if (navigator.permissions && navigator.permissions?.query) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    if (['granted', 'prompt'].includes(result.state)) {
                        navigator.geolocation.getCurrentPosition(getCurrentPositionCallback)
                    }
                })
            } else {
                navigator.geolocation.getCurrentPosition(getCurrentPositionCallback)
            }
        }
    }, [])

    const getCurrentPositionCallback = (position) => {
        const coords = position?.coords
        const { latitude, longitude } = coords

        // set the current position
        setPosition({
            lat: latitude,
            lng: longitude,
        })
    }

    useEffect(() => {
        if (!position) initCurrentPosition()
    }, [initCurrentPosition, position])

    const createGallery = (data) => {
        let galleryActivity = []
        let photoItem = {}

        if (data.activity.gallery) {
            // eslint-disable-next-line
            data.activity.gallery.map((photo) => {
                photoItem = {
                    key: '',
                    src: '',
                    width: '100%',
                }
                photoItem.key = photo.id.toString()
                photoItem.src = photo.source
                galleryActivity.push(photoItem)
            })
        }

        setActivityGallery(galleryActivity)
    }

    const photoPlaceholder = [
        {
            key: 'photo1',
            src: '/images/general/image_placeholder_cover.jpg',
            width: '100%',
        },
    ]

    // template
    return !isLoading ? (
        <>
            <Navbar linkBack="/search" />
            <Gallery
                photos={
                    data.activity.gallery
                        ? data.activity.gallery.length > 0
                            ? activityGallery
                            : photoPlaceholder
                        : photoPlaceholder
                }
            />
            <Container
                style={{
                    width: '75%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                }}
            >
                <div class="grid-container">
                    <div class="column column-2">
                        <div style={{ textAlign: 'left' }}>
                            <Header
                                className="activity-name-header"
                                as="h1"
                                content={data.activity.name}
                                style={{ marginTop: '0.5rem', marginBottom: '0.25rem', fontSize: '3rem' }}
                            />
                            {data.is_highlighted && data.event_type === 'solidarity' && (
                                <Label color="green" className="special-event">
                                    #APevent
                                </Label>
                            )}

                            {!data.is_highlighted && data.event_type === 'solidarity' && (
                                <Label color="blue">#APsolidarity</Label>
                            )}

                            {data.event_type !== 'solidarity' && data.is_paid && <Label color="red">#APwalkIn</Label>}

                            {data.event_type !== 'solidarity' && !data.is_paid && (
                                <Label color="yellow">#APimage</Label>
                            )}

                            {data.is_discounted && (
                                <Label tag color="pink" style={{ marginLeft: '1.5rem' }}>
                                    Zľavnené
                                </Label>
                            )}
                        </div>
                    </div>
                    <div class="column column-1">
                        <div
                            className="price-info"
                            style={{
                                textAlign: 'right',
                                marginTop: '2.5rem',
                                display: data.is_paid ? 'block' : 'none',
                            }}
                        >
                            {data.is_discounted ? (
                                <div>
                                    <div>
                                        <span style={{ textDecoration: 'line-through', fontWeight: 'bold' }}>
                                            {data.activity.price} €
                                        </span>
                                        <span
                                            style={{
                                                color: 'var(--primary)',
                                                fontWeight: 'bold',
                                                fontSize: '2rem',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            {data.activity.discountPrice} €
                                        </span>
                                    </div>
                                    <div>
                                        <Label circular color="pink">
                                            {Math.round((1 - data.activity.discountPrice / data.activity.price) * -100)}
                                            %
                                        </Label>
                                    </div>
                                </div>
                            ) : (
                                <span
                                    style={{
                                        color: 'var(--primary)',
                                        fontWeight: 'bold',
                                        fontSize: '2rem',
                                        marginLeft: '1rem',
                                    }}
                                >
                                    {data.activity.price} €{' '}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </Container>

            <ActivityInfoSection
                data={data}
                getQueryParamsToNavigation={getQueryParamsToNavigation}
                position={position}
            />

            <Footer />
        </>
    ) : (
        <div style={{ marginTop: '5rem', textAlign: 'center' }}>
            <Loader active inline />
            <h3>Načítavam aktivitu...</h3>
        </div>
    )
}
