import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
// store
import { planningService } from '../../../../store/services/ServicePlanning'
import { truncateString } from '../../../../store/services/helpers/functions'
// components
import { Card, Header, Popup, Button, Label, Icon, Grid, Divider } from 'semantic-ui-react'
// others
import moment from 'moment'
moment.locale('sk')

// component
function PlanningEvent({
    handleModalEditEventOpen,
    handleModalDeleteEventOpen,
    changeModalEditEventInfo,
    changeModalDeleteEventInfo,
    eventInfo,
}) {
    // state
    const user = useSelector((state) => state.user)
    const [isHighlighted, setIsHighlighted] = useState(eventInfo.is_highlighted)
    const [popupIsOpen, setPopupIsOpen] = useState(false)
    const [currentDate] = useState(new Date())

    // component functions
    const startEditEvent = () => {
        handlePopupClose()
        changeModalEditEventInfo(eventInfo)
        handleModalEditEventOpen()
    }

    const startDeleteEvent = () => {
        handlePopupClose()
        changeModalDeleteEventInfo(eventInfo)
        handleModalDeleteEventOpen()
    }

    const handlePopupOpen = () => {
        setPopupIsOpen(true)
    }

    const handlePopupClose = () => {
        setPopupIsOpen(false)
    }

    const handleEventHightlight = async () => {
        const event = eventInfo.id
        if (isHighlighted === false) {
            const request = await planningService.markAsHighlighted(event, 'POST')

            if (request.status === 200) {
                setIsHighlighted(true)
            }
        } else {
            const request = await planningService.markAsHighlighted(event, 'DELETE')

            if (request.status === 200) {
                setIsHighlighted(false)
            }
        }
    }

    // template
    return (
        <Popup
            on="click"
            size="huge"
            wide
            position="top right"
            open={popupIsOpen}
            onOpen={handlePopupOpen}
            onClose={handlePopupClose}
            trigger={
                <Card fluid raised>
                    {(eventInfo.is_discounted || eventInfo.is_paid) && (
                        <Label attached="top" basic>
                            <strong>
                                {eventInfo.is_paid &&
                                    (user.is_town ? 'PRISPEVKOVÝ TERMÍN' : 'PLATENÝ TERMÍN') +
                                        (eventInfo.is_discounted ? ' + ZĽAVA' : '')}
                            </strong>
                        </Label>
                    )}
                    <Card.Content>
                        <Header as="h4">
                            <Header as="h5" style={{ marginBottom: '0.8rem' }}>
                                {truncateString(eventInfo.activity.name, 20)}
                            </Header>
                            <div>
                                {`${moment(eventInfo.starts).format('HH:mm')} - ${moment(eventInfo.ends).format(
                                    'HH:mm'
                                )}`}
                            </div>
                        </Header>
                    </Card.Content>
                    <Card.Content extra>
                        {isHighlighted && user.is_town && (
                            <Label color="green" style={{ width: '100%' }}>
                                #APevent
                            </Label>
                        )}

                        {!isHighlighted && user.is_town && (
                            <Label color="blue" style={{ width: '100%' }}>
                                #APsolidarity
                            </Label>
                        )}

                        {!user.is_town && eventInfo.is_paid && (
                            <Label color="red" style={{ width: '100%' }}>
                                #APwalkIn
                            </Label>
                        )}

                        {!user.is_town && !eventInfo.is_paid && (
                            <Label color="yellow" style={{ width: '100%' }}>
                                #APimage
                            </Label>
                        )}
                    </Card.Content>
                </Card>
            }
        >
            <Fragment>
                <Popup.Header>
                    <Grid>
                        <Grid.Row verticalAlign="middle" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={12} tablet={12} mobile={12} style={{ paddingLeft: 0 }}>
                                <Header as="h2" content={eventInfo.activity.name} />
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={4} mobile={4} style={{ textAlign: 'right' }}>
                                {user.is_town && (
                                    <Icon
                                        title={isHighlighted ? 'Zrušiť označenie' : 'Označiť ako zvyraznený'}
                                        name={isHighlighted ? 'star' : 'star outline'}
                                        color="yellow"
                                        onClick={() => handleEventHightlight()}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Popup.Header>
                <Divider />
                <Popup.Content>
                    <div style={{ fontSize: '0.9rem' }}>
                        <Icon name="calendar alternate outline" />
                        {`${moment(eventInfo.starts).format('DD.MM.YYYY HH:mm')} - ${moment(eventInfo.ends).format(
                            'DD.MM.YYYY HH:mm'
                        )}`}
                    </div>
                    <div style={{ fontSize: '0.9rem' }}>
                        <Icon name="map marker alternate" />
                        {eventInfo.address.name}
                    </div>

                    <Divider />
                    <Button.Group
                        fluid
                        floated="left"
                        style={
                            moment(eventInfo.starts).isBefore(moment(currentDate))
                                ? { display: 'none' }
                                : { marginTop: '0' }
                        }
                    >
                        <Button onClick={startEditEvent}>Upraviť</Button>
                        <Button color="red" onClick={startDeleteEvent}>
                            Zrušiť udalosť
                        </Button>
                    </Button.Group>
                </Popup.Content>
            </Fragment>
        </Popup>
    )
}

export default PlanningEvent
