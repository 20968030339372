import React from 'react'
import { Link } from 'react-router-dom'
import Sticky from 'react-stickynode'
// store
import { routes } from '../../store/routes'
// components
import { Icon, Button, Dropdown, Popup } from 'semantic-ui-react'
import BasketIcon from './BasketIcon'
import Autocomplete from 'react-google-autocomplete'

// component
function Navbar(props) {
    return (
        <>
            <div
                className="navbar"
                style={props.isSearch ? styles.navbar : { ...styles.navbar, justifyContent: 'space-between' }}
            >
                {/* Logo Section */}
                {props.isHome ? (
                    <div className="navbar-logo" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                        <Link to={routes.HOME}>
                            <img src="/images/logos/logo_dark_version.png" width="155rem" alt="logo" />
                        </Link>
                    </div>
                ) : (
                    <div className="navbar-logo hidden-mobile" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                        <Link to={routes.HOME}>
                            <img src="/images/logos/logo_dark_version.png" width="155rem" alt="logo" />
                        </Link>
                    </div>
                )}

                {/* Search Section */}
                <div
                    className={`navbar-search ${props.isSearch ? 'with-search' : ''}`}
                    style={{ ...styles.search, paddingTop: '1rem', paddingBottom: '1rem' }}
                >
                    {props.isSearch ? (
                        <div className="filtering-container">
                            <div style={styles.filtering}>
                                <Icon name="map marker alternate" style={{ color: 'grey' }} />
                                <Autocomplete
                                    autoFocus
                                    autoComplete="new-password"
                                    id="map-search-bar"
                                    style={styles.autocomplete}
                                    placeholder="Zadaj miesto..."
                                    onPlaceSelected={props.onSelect}
                                    types={[]}
                                    componentRestrictions={{ country: ['sk', 'cz'] }}
                                />
                                <Popup
                                    trigger={
                                        <Button
                                            type="button"
                                            loading={props.searching}
                                            disabled={props.searching}
                                            onClick={props.searchConfig}
                                            style={{
                                                ...styles.transparentButton,
                                                marginRight: '1rem',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            <Icon name="search" style={styles.icon} />
                                        </Button>
                                    }
                                    content={'Upresniť vyhľadávacie možnosti'}
                                />
                                <Popup
                                    trigger={
                                        <Button
                                            type="button"
                                            onClick={props.searchMyLocation}
                                            style={{
                                                ...styles.transparentButton,
                                                marginRight: '1rem',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            <Icon name="crosshairs" style={styles.icon} />
                                        </Button>
                                    }
                                    content={'Nastaviť moju polohu'}
                                />
                                <Dropdown
                                    icon={<Icon name="ellipsis vertical" style={styles.icon} />}
                                    button
                                    style={{ ...styles.transparentButton, marginRight: '1rem', marginLeft: '1rem' }}
                                >
                                    <Dropdown.Menu className="map-menu-dropdown">
                                        <Dropdown.Item as={Link} to={'/login'}>
                                            Prihlásiť sa
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    ) : (
                        <div className="mobile-nav-block">
                            <Link to={props.linkBack} className="btnSearch" style={styles.centeredText}>
                                Zadaj miesto
                            </Link>
                        </div>
                    )}
                </div>

                {/* User Actions Section */}
                {!props.isSearch && (
                    <div className={`navbar-icons ${props.isHome ? '' : 'force-md-center'}`} style={styles.icons}>
                        <Link to={routes.LOGIN}>
                            <Icon name="user" size="large" />
                        </Link>
                    </div>
                )}
            </div>
            <Sticky innerZ={10}>
                <BasketIcon />
            </Sticky>
        </>
    )
}

const styles = {
    navbar: {
        //fontFamily: 'Avenir Next LT Pro',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.1rem auto', // Center horizontally
        maxWidth: '1200px', // Set maximum width
        width: '100%', // Ensure responsiveness
    },
    searchContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    filtering: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid lightgrey',
        width: '100%',
    },
    autocomplete: {
        padding: '0.8rem',
        fontSize: '12px',
        textAlign: 'left',
        background: 'transparent',
        flexGrow: 1,
    },
    transparentButton: {
        background: 'transparent',
        padding: '0',
    },
    icon: {
        marginRight: '0',
        fontSize: '16px',
    },
    centeredText: {
        textAlign: 'center',
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
    },
}

export default Navbar
