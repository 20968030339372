import { APIRequest } from './helpers/requests'
import { API } from '../config'

export const userService = {
    getProviders,
    createProvider,
    setProviderActivity,
    setProviderAsCouch,
    setProviderAsTown,
    registerProvider,
}

async function getProviders() {
    return await APIRequest({
        url: API.GET_PROVIDERS,
        method: 'GET',
        private: true,
    })
}
async function createProvider(input) {
    return await APIRequest({
        url: API.GET_PROVIDERS_CREATE,
        method: 'POST',
        data: {
            email: input.email,
            provider_type: input.provider_type,
            id_number: input.id_number,
            is_town: input.is_town,
            is_coach: input.is_coach,
            firstname: input.firstname,
            lastname: input.lastname,
            nickname: input.nickname,
        },
        private: true,
    })
}

async function registerProvider(data) {
    return await APIRequest({
        url: API.GET_PROVIDERS_REGISTER,
        method: 'POST',
        data: data,
        private: true,
    })
}
async function setProviderActivity(id) {
    return await APIRequest({
        url: API.SET_PROVIDER_ACTIVE_STATUS + id,
        method: 'PATCH',
        private: true,
    })
}
async function setProviderAsTown(id) {
    return await APIRequest({
        url: API.SET_PROVIDER_TOWN_STATUS + id,
        method: 'PATCH',
        private: true,
    })
}
async function setProviderAsCouch(id) {
    return await APIRequest({
        url: API.SET_PROVIDER_COACH_STATUS + id,
        method: 'PATCH',
        private: true,
    })
}
